/* Ma01MemoriasAycardi.css */
.contenedor {
  display: flex;
  justify-content: space-between; /* Alinea los hijos (columnas) con espacio entre ellos */
  height: 100vh; /* O cualquier otra altura que prefieras */
  /* ...otros estilos si los necesitas */
}





.columnaTercera {  
  flex: 35%; /* Esto toma el 40% del espacio disponible */  
  width: 40%; /* O cualquier otra anchura que prefieras */
  margin-top: 15px;
  padding: 15px;

}


/****Inicio Zona primera columna-Elementos****/
/****Inicio Zona primera columna-Elementos****/
/****Inicio Zona primera columna-Elementos****/
.columnaElementos {
  flex: 10%; /* Esto toma el 20% del espacio disponible */
  

  
}
.zonaPdf {
  background-color: #0f1322; /*original black*/
  border-radius: 20px;
  height: 100vh;  
  margin: 15px;  
  margin-top: 30px;
  height: 100%; /* Ocupar el alto completo del contenedor padre */
  overflow-y: auto; /* Habilita la barra de desplazamiento vertical cuando sea necesario */
  width: 90%; /* O cualquier otra anchura que prefieras */

  
}

/* Personaliza el ancho y el fondo de la barra de desplazamiento */
.zonaPdf::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
  background-color: #091c46; /* Color de fondo de la barra */
}

/* Personaliza el pulgar (la parte que se arrastra) */
.zonaPdf::-webkit-scrollbar-thumb {
  background-color: #ffab2d; /* Color naranja */
  border-radius: 10px; /* Bordes redondeados */
  border: 3px solid #091c46; /* Borde alrededor del pulgar */
}

/* Personaliza el track (la pista sobre la que se mueve el pulgar) */
.zonaPdf::-webkit-scrollbar-track {
  background-color: #091c46; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}


.tituloZonaPdf {
  background-color: #ffab2d; /* Naranja */
  
  padding: 5px; /* Ajusta según tu necesidad */
  margin-bottom: 10px;
}

.tituloZonaPdf h2 {
  color: #091c46; /* Letras azules */
  margin: 0; /* Elimina el margen para evitar espacio extra dentro del div */
  padding:0px; /* Ajusta según tu necesidad */
}

.contenedor-elemento {
  background-color: #091c46; /* Fondo negro */
  color: white; /* Texto blanco */
  display: flex; /* Usamos flexbox para alinear y justificar contenido */
  justify-content: space-between; /* Esto separa el texto y el botón de eliminación */
  align-items: center; /* Alineación vertical de los elementos */
  padding:2px; /* Espacio interior alrededor del texto y el botón */
  margin-bottom: 10px; /* Espacio entre elementos renderizados */
  border-radius: 5px; /* Opcional: si quieres esquinas ligeramente redondeadas */
  margin-left: 5px;
  margin-right: 5px;
}

.texto-elemento {
  text-align: left; /* Asegura que el texto esté alineado a la izquierda */
}

/****Fin Zona primera columna-Elementos****/
/****Fin Zona primera columna-Elementos****/
/****Fin Zona primera columna-Elementos****/



/****Inicio Zona Subir PDFS****/
/****Inicio Zona Subir PDFS****/
/****Inicio Zona Subir PDFS****/
.columnaCapitulos {
  flex: 40%; /* Esto toma el 40% del espacio disponible */
  width: 40%; /* O cualquier otra anchura que prefieras */
  margin-top: 15px;
  padding: 15px;
}

.TituloSubirPDF {
  background-color: #091c46;
  padding: 5px; /* Espacio interior para asegurar que el contenido no esté pegado a los bordes */
  border-top-left-radius: 10px; /* Bordes superiores redondeados */
  border-top-right-radius: 10px; /* Bordes superiores redondeados */
  border-bottom-left-radius: 0; /* Bordes inferiores rectos */
  border-bottom-right-radius: 0; /* Bordes inferiores rectos */
  margin-bottom: 30px; /* Añade un espacio entre el título y el siguiente elemento */
}



.TituloSubirPDF h2 {
  color: #ffab2d; /* Color de texto naranja */
  margin: 0; /* Remover margen predeterminado */
}
/* Asumiendo que quieres espacio alrededor de los otros elementos también */
.creacionCapitulos {
  display: flex; /* Establecer como flexbox */
  justify-content: space-between; /* Separar el input y el botón */
  background-color: #091c46;
  padding: 6px; /* Espacio interior */
  /* ... tus otros estilos ... */
}

.TituloSubirPDF2 {
  background-color: #091c46;
  padding: 5px; /* Espacio interior para asegurar que el contenido no esté pegado a los bordes */
  border-top-left-radius: 0px; /* Bordes superiores redondeados */
  border-top-right-radius: 0px; /* Bordes superiores redondeados */
  border-bottom-left-radius: 0; /* Bordes inferiores rectos */
  border-bottom-right-radius: 0; /* Bordes inferiores rectos */
  
  margin-top: 30px;
}

.TituloSubirPDF2 h2 {
  color: #ffab2d; /* Color de texto naranja */
  margin: 0; /* Remover margen predeterminado */
}


/****Fin Zona Subir PDFS****/
/****Fin Zona Subir PDFS****/
/****Fin Zona Subir PDFS****/


.zona-arrastre {
  border: 2px dashed black; /* Borde punteado para indicar área de arrastre */
  background-color: #ffc165; /* Color de fondo */
  color: black; /* Color del texto */
  padding: 20px; /* Espaciado interno */
  text-align: center; /* Alinear el contenido al centro */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el área */
  height: 140px; /* Altura fija del área de arrastre */
  display: flex;
  align-items: center; /* Centrar el contenido verticalmente */
  justify-content: center; /* Centrar el contenido horizontalmente */
  gap: 20px; /* Espacio entre elementos internos */
}

.zona-arrastre:hover {
  background-color: #ffab2d; /* Cambiar el color de fondo cuando el mouse pasa sobre el área */
}

/* Estilos para el texto indicativo */
.texto-indicativo {
  margin: 0;
  font-size: 1.5em; /* Tamaño del texto */
  font-weight: bold; /* Grosor del texto */
}

.bloquecapitulos {
  margin-top: 20px;
  
  max-height: 550px; /* o cualquier altura que funcione bien para tu diseño */
  overflow-y: auto;

  background-color:white;
  border-radius: 10px; /* Esto redondeará las esquinas del borde. "10px" es un valor que da un redondeo suave; también puedes ajustarlo a tu preferencia. */ 
  padding-top: 15px;
}


/* Personaliza el ancho y el fondo de la barra de desplazamiento */
.bloquecapitulos::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
  background-color: white; /* Color de fondo de la barra */
}

/* Personaliza el pulgar (la parte que se arrastra) */
.bloquecapitulos::-webkit-scrollbar-thumb {
  background-color: #091c46; /* Color naranja */
  border-radius: 10px; /* Bordes redondeados */
  border: 3px solid white; /* Borde alrededor del pulgar */
}

/* Personaliza el track (la pista sobre la que se mueve el pulgar) */
.bloquecapitulos::-webkit-scrollbar-track {
  background-color: white; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}

.vistapreviaPortada {

  margin-top: 20px;
  max-height: 350px; /* o cualquier altura que funcione bien para tu diseño */
  overflow-y: auto;
  background-color: rgb(210, 210, 218);
}

/* Personaliza el ancho y el fondo de la barra de desplazamiento */
.vistapreviaPortada::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
  background-color: white; /* Color de fondo de la barra */
}

/* Personaliza el pulgar (la parte que se arrastra) */
.vistapreviaPortada::-webkit-scrollbar-thumb {
  background-color: #091c46; /* Color naranja */
  border-radius: 10px; /* Bordes redondeados */
  border: 3px solid white; /* Borde alrededor del pulgar */
}

/* Personaliza el track (la pista sobre la que se mueve el pulgar) */
.vistapreviaPortada::-webkit-scrollbar-track {
  background-color: white; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}





/* estilos.css */

.contenedor-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.input-estilo {
  flex-grow: 1; /* Permite que el input crezca para llenar el espacio */
  margin-right: 10px; /* Espacio entre el input y el botón */
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid rgb(83, 79, 79); /* especifica el ancho, estilo, y color del borde */
  border-radius: 5px;
  outline: none; /* opcional: elimina el contorno al enfocar el input */
  margin-right: 10px;
  flex: 1;
  min-width: 200px;
}


.boton-estilo {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #FFAB2D;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.boton-estilo:hover {
  background-color: #ffcc00; /* o cualquier otro color que desees al pasar el mouse */
  transform: scale(1.05);
}

.boton-estilo:active {
  background-color: #FFAB2D;
  transform: scale(1);
}

/*Capitulos Arrastables*/
.capitulo-arrastable {
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #FFAB2D; /* un gris azulado oscuro */
  color: #0f1322;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  cursor: grab; /* cambia el cursor a una "mano" que parece agarrar, indicando que esto se puede arrastrar */
}

.capitulo-arrastable:hover {
  background-color: #8c92a0; /* un poco más claro que el color de fondo normal */
}
/*Area Drop*/
.area-drop {
  border: 2px dashed #88C0D0; /* un azul claro */
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  background-color: white; /* un gris muy oscuro */
  color: black; /* un gris muy claro, casi blanco */
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.area-drop:hover {
  border-color: #81A1C1; /* un azul un poco más oscuro */
}

.area-drop.active {
  border-color: #ce4f5b; /* un rojo */
  background-color: #a1acc7; /* un gris azulado oscuro */
}



.boton-estilo2 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3B4252;
  color: #FFAB2D;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.boton-estilo2:hover {
  background-color: #ffcc00; /* o cualquier otro color que desees al pasar el mouse */
  transform: scale(1.05);
  color: black;
}

.boton-estilo2:active {
  background-color: #FFAB2D;
  transform: scale(1);
}

/* Estilos para el contenedor del botón, si es necesario */
.boton-contenedor {
  width: 100%; /* Hace que el contenedor ocupe todo el ancho de su elemento padre */
}

.boton-estilo2 {
  width: 100%; /* Hace que el botón ocupe todo el ancho de su contenedor */
  padding: 10px; /* Espaciado interno para hacer el botón más grande */
  border: none; /* Remueve el borde por defecto */
  background-color: #FDB515; /* Color de fondo que contrasta con el azul oscuro, en este caso, amarillo */
  color: #003366; /* Color del texto que contrasta con el amarillo, en este caso, azul oscuro */
  font-size: 16px; /* Tamaño del texto */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  transition: background-color 0.3s, color 0.3s; /* Hace la transición de colores suave */
  border-radius: 5px; /* Bordes redondeados */
}

.boton-estilo2:hover {
  background-color: #FFD733; /* Color de fondo ligeramente más claro al pasar el cursor */
  color: #004680; /* Color de texto ligeramente más claro al pasar el cursor */
}

.boton-estilo2:active {
  background-color: #E6A600; /* Color de fondo más oscuro al hacer click */
  color: #002E4D; /* Color de texto más oscuro al hacer click */
}

/* Estilos para la barra de menú */
.barra-menu {
  /* Estilos por defecto (móvil) */
  margin-top: 35px; 
  width: 100%;
  background-color: #091c46;
  padding: 10px 0;
  display: flex;
  justify-content: left;
}

/* Media query para tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .barra-menu {
    margin-top: 35px; /* Margen para tablet */
  }
}

/* Media query para desktop */
@media (min-width: 1024px) {
  .barra-menu {
    margin-top: 1px; /* Margen para desktop */
  }
}



/* Estilos para los botones dentro de la barra de menú */
.boton-menu {
  background-color: #091c46; /* Color de fondo para los botones, uno más tenue que la barra */
  color: #E6A600; /* Color del texto de los botones */
  padding: 5px 10px; /* Espaciado interno para los botones */
  margin: 0 10px; /* Espaciado externo para los botones, separándolos entre sí */
  margin-left: 15px;
  border-radius: 8px;
  border: none; /* Elimina el borde por defecto de los botones */
  cursor: pointer; /* Cambia el cursor al pasar sobre los botones */
  transition: background-color 0.3s; /* Transición suave del color de fondo */
}

.boton-menu:hover {
  background-color: #FFAB2D; /* Color de fondo cuando se pasa el cursor */
  color: #091c46; /* Color del texto de los botones */
}
.boton-menu:active {
  background-color: #f1f500; /* Un color más oscuro que el original */
  color: #FFD47A; /* Un tono de amarillo más claro para mantener el contraste */
}



.boton-formato {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 8px; /* Esta línea da los bordes redondeados al botón */
  transition: all 0.3s ease;
  cursor: pointer;
}

.boton-formato:hover {
  background-color: #f5f5f5;
}

.boton-formato.formato-activo {
  background-color: #481b96;
  color: #fff;
}










