.formulario-proyecto {
  background-color: #FFAB2D;
  padding: 20px;
  color: #091c46;
  display: flex;
  flex-wrap: wrap; /* Esto permite que los elementos se ajusten en filas y columnas según el espacio disponible */
  gap: 10px; /* Ajusta este valor para controlar el espacio horizontal y vertical entre los campos */
  width: 100%;
  box-sizing: border-box; /* Asegura que el padding y el borde estén incluidos en el ancho total */
  margin: auto;
  border: 2px solid #3B4252;
}
  
.formulario-proyecto label {
  display: flex;
  flex-direction: column; /* Mantiene el input debajo del texto de la etiqueta */
  width: calc(50% - 10px); /* Deduce el espacio del gap para que realmente tenga dos columnas */
  margin-bottom: 0; /* Eliminado para reducir el espacio vertical entre los campos */
}

.input-formulario, .textarea-formulario {
  width: 100%; /* Ajustado para que ocupe el ancho completo de su etiqueta padre */
  padding: 10px;
  box-sizing: border-box; /* Asegura que el padding y el borde estén incluidos en el ancho total */
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: 5px; /* Pequeño espacio entre la etiqueta y el campo de entrada */
}

/* Estilos para el botón, si decides incluir uno más tarde */
.boton-submit {
  width: 100%; /* Cambiado a full-width porque está en su propia fila */
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  /* No se necesita margin-top aquí ya que el gap del contenedor se encarga del espaciado */
}



