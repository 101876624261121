.VistaPrevia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 20px;
  padding: 0 0px;
  box-sizing: border-box;
}

.hoja.tamaño-carta {
  flex: none;
  width: 100%;
  min-width: 0;
  height: 90vh;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.contenido {
  text-align: top;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.contenido p {
  font-size: 18px; /* Aumenta el tamaño de la fuente general */
  margin: 5px 0; /* Espacio entre líneas */
  color: black; /* Asegura que el texto sea siempre negro */
}

