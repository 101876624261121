.modern-input {
    width: 65%; /* Ajusta el ancho a menos del predeterminado */
    padding: 2px 2px; /* Agrega un poco de relleno interior para mayor comodidad al escribir */
    border: 1px solid gray; /* Define un borde sólido y delgado */
    border-radius: 2px; /* Bordes redondeados para un look moderno */
    outline: none; /* Elimina el contorno que aparece al enfocar */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil para profundidad */
    transition: border-color 0.3s, box-shadow 0.3s; /* Transición suave para interacción */
    font-size: 13px; /* Tamaño de fuente confortable */
  }
  
  .modern-input:focus {
    border-color: #007BFF; /* Cambia el color del borde al enfocar */
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); /* Sombra exterior para resaltar el enfoque */
  }
  